
    @use 'sass:map';
    @use 'themes/config/palette';
    @use 'variables' as var;
    @use 'mixins';

    $view-radius: var.$base-radius * 3;

    sb-view > .view-wrapper {
      --mat-sidenav-scrim-color: #{transparentize(map.get(palette.$neutral, 780), 0.33)};
      height: 100%;
      background: transparent;
      border-radius: 0;
      padding: 0;

      @include mixins.media-breakpoint-up(md) {
        border-radius: $view-radius;
        padding-bottom: var.$main-page-padding;
        padding-right: var.$main-page-padding;
      }

      mat-drawer-container.mat-drawer-container.view-drawer-container {
        height: 100%;
        border-radius: 0;
        background: transparent;

        @include mixins.media-breakpoint-up(md) {
          border-radius: $view-radius;
        }

        mat-drawer.mat-drawer.mat-drawer-end {
          border-radius: 0;
          max-width: 80%;

          @include mixins.media-breakpoint-up(md) {
            border-radius: $view-radius;
          }
        }
      }

      main {
        height: 100%;
        overflow: hidden;
        display: grid;
        grid-template-areas:
          'toolbar right-sidebar'
          'content right-sidebar';
        grid-template-columns: 1fr auto;
        grid-template-rows: auto 1fr;
        padding-bottom: 0;

        @include mixins.media-breakpoint-up(md) {
          /* padding-bottom: var.$main-page-padding; */
        }

        > .toolbar {
          grid-area: toolbar;
          border-radius: 0;
          max-width: 100%;
          overflow: hidden;
          padding: var.$view-toolbar-padding-y var.$view-toolbar-padding-x;

          @include mixins.media-breakpoint-up(md) {
            border-radius: $view-radius $view-radius 0 0;
            padding: var.$view-content-padding-y var.$view-content-padding-x-md;
          }
        }

        > .content {
          grid-area: content;
          border-radius: 0;
          overflow: hidden;
          position: relative;
          padding: var.$view-content-padding-y 0 var.$view-content-padding-y var.$view-content-padding-x;

          @include mixins.media-breakpoint-up(md) {
            border-radius: 0 0 $view-radius $view-radius;
            padding: var.$view-content-padding-y 0 var.$view-content-padding-y var.$view-content-padding-x-md;
          }

          > .scrollable-container {
            height: 100%;
            overflow-y: scroll;
            padding-right: var.$view-content-padding-x;

            @include mixins.media-breakpoint-up(md) {
              padding-right: var.$view-content-padding-x-md;
              /* padding-bottom: 3rem; */
            }
          }
        }

        > .right-sidebar {
          grid-area: right-sidebar;
          min-width: 0;

          @include mixins.media-breakpoint-up(md) {
            /* min-width: var.$main-page-padding; */
            margin-right: -(var.$main-page-padding);
          }
        }
      }
    }
  