
      @use 'variables' as var;

      sb-view-header {
        display: block;
        padding-top: var.$view-header-padding-top;
      }

      sb-view main > .toolbar {
        > div > sb-view-header:last-child,
        > sb-view-header:last-child {
          padding-bottom: var.$view-header-margin-bottom;
        }
      }
    